.button-style {
  background-color: #180018;
  color: white;
  border: none;
  border-radius: 15px;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}

.button-style:hover {
  background-color: #9d084a;
  color: white;
}

.button-style:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  cursor: not-allowed;
}
