.navbar {
  background-color: #9d084a !important;
}

.header {
  font-family: "Degradasi";
  font-size: 1.5rem;
  color: #f5e3e0 !important;
}

.brand {
  font-family: "Degradasi";
  font-size: 1.5rem;
  color: #f5e3e0 !important;
}

.line {
  height: 8px;
  background: white;
  color: white;
}

.sidebar {
  background-color: #180018 !important;
}

.toggle {
  border: none !important;
  color: #f5e3e0 !important;
  padding: 10px !important;
}

.active {
  color: #180018 !important;
  background-color: #f5e3e0 !important;
  font-family: "Poppins-SemiBold";
  font-size: 1.1em !important;
  border-radius: 10px;
}

.navItem {
  color: #f5e3e0 !important;
  font-family: "Poppins-SemiBold";
  font-size: 1.1em !important;
  border-radius: 10px;
}

.logoutItem {
  color: #180018 !important;
  font-family: "Poppins-SemiBold";
  font-size: 1.1em !important;
  border-radius: 10px;
}

.logoutItem:hover {
  color: #9d084a !important;
}

.navItem:hover {
  color: #180018 !important;
  background-color: #f5e3e0 !important;
}

@media screen and (min-width: 1200px) {
  .sidebar {
    width: 20% !important;
  }
}

.logout {
  margin-top: auto !important;
  background-color: #f5e3e0 !important;
}

.name {
  font-family: "Poppins-SemiBold";
  font-size: 1.3em;
  color: #f5e3e0;
}

.detail {
  font-family: "Poppins-Medium";
  font-size: 1em;
  color: #f5e3e0;
}

.empId {
  font-family: "Poppins-Regular";
  color: #e8b4bc;
}

.icon {
  color: #f5e3e0;
}

.rightNav {
  color: #f5e3e0;
  font-family: "Poppins-Medium";
}
