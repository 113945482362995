.header {
  font-family: "Poppins-SemiBold";
}

.message {
  font-family: "Poppins-Medium";
}

.comment {
  font-family: "Poppins-Regular";
}

.bold {
  font-family: "Poppins-Bold";
}

.mute {
  font-family: "Poppins-Light";
}
