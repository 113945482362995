.label {
  font-family: "Degradasi";
  color: #3a3238;
}

.navHeader {
  font-family: "Poppins-Medium";
  margin-right: 5px;
}

.activeTab {
  font-family: "Poppins-Bold";
  color: #f5e3e0 !important;
  background-color: #9d084a !important;
}

.inactiveTab {
  color: #f5e3e0 !important;
  background-color: #180018 !important;
}

.form {
  font-family: "Poppins-Regular";
}

.formLabel {
  font-family: "Poppins-Medium";
}

.formBox {
  border: grey 1px solid !important;
}

.formPasswordBox {
  border: grey 1px solid !important;
  border-right: none !important;
}

.circle {
  width: 10em;
  height: 10em;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.card {
  border-top-left-radius: 0 !important;
}

.iconEye {
  background-color: white !important;
  border-top: grey 1px solid !important;
  border-right: grey 1px solid !important;
  border-bottom: grey 1px solid !important;
}
