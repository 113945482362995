.Login {
  height: 100vh;
}

.content {
  height: 100%;
  z-index: 2;
  position: relative;
}

.line1 {
  font-family: "Poppins-SemiBold";
  font-size: 60px;
  color: #180018;
}

.line2 {
  font-family: "Degradasi";
  font-size: 60px;
  color: #9d084a;
  line-height: 1;
}

.icon {
  color: #180018;
}

.formBox {
  background-color: #e8b4bc !important;
  border: none !important;
  font-family: "Poppins-Regular";
}

.passwordBox {
  background-color: #e8b4bc !important;
  border: none !important;
  font-family: "Poppins-Regular";
}

.iconBox {
  background-color: #e8b4bc !important;
  border: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.iconEye {
  background-color: #e8b4bc !important;
  border: none !important;
}

.password {
  font-family: "Poppins-Light";
  border: none;
  cursor: pointer;
}

.modalTitle {
  font-family: "Degradasi";
  color: #9d084a !important;
  vertical-align: middle;
}

.modalBody {
  font-family: "Poppins-Medium";
  text-align: center;
  color: #180018;
}

.image {
  margin-right: 10px;
}

.toastHeader {
  font-family: "Poppins-Medium" !important;
  color: white !important;
  background-color: #b22222 !important;
}

.toast {
  font-family: "Poppins-Regular" !important;
  color: #b22222 !important;
}

.msButton {
  color: #180018 !important;
  background-color: #ffffff !important;
  border: 2px solid #180018 !important;
  border-radius: 10px;
  font-family: "Poppins-SemiBold" !important;
  font-size: 16px !important;
}

.spinner {
  color: #9d084a;
  font-family: "Poppins-Medium";
  vertical-align: middle;
  margin-right: 10px;
}
