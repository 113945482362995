.App {
  min-height: 100vh;
  overflow-y: hidden;
}
.bg {
  background-image: url("../images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.no-bg {
  background-color: #f5e3e0;
}
