.background {
  height: 100vh;
  background-color: #180018;
}

.header {
  background-color: #9d084a;
  padding: 10px;
  padding-left: 30px;
  padding-top: 20px;
  font-family: "Degradasi";
  margin-bottom: 2rem;
}

.icon {
  color: #180018 !important;
  cursor: pointer;
}

.brand {
  font-family: "Degradasi";
  font-size: 2rem;
  color: #9d084a;
  vertical-align: middle;
}

.formIcon {
  color: #180018;
}

.iconBox {
  background-color: white !important;
}

.form {
  font-family: "Poppins-Regular" !important;
}

.image {
  margin-right: 10px;
}

.toastHeader {
  font-family: "Poppins-Medium" !important;
  color: white !important;
  background-color: #b22222 !important;
}

.toast {
  font-family: "Poppins-Regular" !important;
  color: #b22222 !important;
}

.spinner {
  color: #9d084a;
  font-family: "Poppins-Medium";
  vertical-align: middle;
  margin-right: 10px;
}

.iconEye {
  background-color: white !important;
}

.formBox {
  border-right: none !important;
}
