.label {
  font-family: "Degradasi";
  color: #180018;
}

.employeeDetails {
  font-family: "Poppins-Medium";
}

.searchBar {
  font-family: "Poppins-Regular";
  border-radius: 7px;
  border: none;
}

.filterText {
  font-family: "Poppins-Medium";
}

.tableHeader {
  background-color: #180018 !important;
  color: white !important;
  font-family: "Poppins-Medium";
  border-radius: 10px 10px 0 0 !important;
}

.tableBody {
  font-family: "Poppins-Regular";
  border-radius: 0 0 0 0 !important;
  cursor: pointer;
}

.tableBody:hover {
  background-color: rgb(232, 232, 232);
}

.cardHeader {
  background-color: #e8b4bc !important;
  font-family: "Poppins-SemiBold";
  font-size: 1.2rem;
}

.swtdHeader {
  background-color: #e8b4bc !important;
  font-family: "Poppins-Bold";
  border-radius: 10px 10px 0 0 !important;
}

.cardBody {
  font-family: "Poppins-Regular" !important;
}

.formLabel {
  font-family: "Poppins-SemiBold";
}

.categoryLabel {
  font-family: "Poppins-SemiBold";
}

.formBox {
  border: grey 1px solid !important;
}

.pointsBox {
  border: grey 1px solid !important;
  font-family: "Poppins-SemiBold";
  color: #9d084a !important;
}

.points {
  font-family: "Poppins-SemiBold";
  color: #9d084a !important;
}

.pointsDisplay {
  padding-left: 1%;
  padding-right: 1%;
  background-color: white;
  border-radius: 5px;
  border: #9d084a solid;
  color: #9d084a;
}

.triangle {
  cursor: pointer;
}

.iconBox {
  background-color: #e8b4bc !important;
  color: #180018 !important;
}

.button {
  background-color: #9d084a !important;
  border: none !important;
}

.commentEdit {
  cursor: pointer;
  color: #180018;
}

.commentDelete {
  cursor: pointer;
  color: red;
}

.comment {
  font-family: "Poppins-Light";
}

.imgProof {
  height: 60%;
  width: 60%;
}

.msg {
  font-family: "Poppins-SemiBold";
  color: #9d084a;
}

.termPoints {
  display: flex;
  flex-direction: column;
  font-family: "Degradasi";
  line-height: 1.3em;
}

.userStat {
  font-family: "Poppins-Bold";
}

.validPoints {
  font-size: 2em;
}

.requiredPoints {
  font-size: 1.2em;
}

.loading {
  color: #9d084a;
  font-family: "Poppins-SemiBold";
}

.statCard {
  border: 1px solid #180018 !important;
}

.pendingStat {
  padding: 0% !important;
  font-family: "Poppins-SemiBold";
  border-bottom: 1px solid #180018 !important;
  color: white !important;
  background-color: #180018 !important;
}

.rejectedStat {
  padding: 0% !important;
  font-family: "Poppins-SemiBold";
  border-bottom: 1px solid #180018 !important;
  color: white !important;
  background-color: #9d084a !important;
}

.approvedStat {
  padding: 0% !important;
  font-family: "Poppins-SemiBold";
  border-bottom: 1px solid #180018 !important;
  color: #180018 !important;
  background-color: #e8b4bc !important;
}

.totalStat {
  padding: 0% !important;
  font-family: "Poppins-SemiBold";
  border-bottom: 1px solid #180018 !important;
  color: #180018 !important;
  background-color: white !important;
}

.statBody {
  padding: 3% !important;
  font-family: "Poppins-SemiBold";
  color: #180018 !important;
}

.graphBackground {
  background-color: white;
  border-radius: 3px;
  font-family: "Poppins-Medium";
}

.iconNumber {
  background-color: #9d084a;
  color: white;
  border-radius: 5px;
}

.pageNum {
  --bs-pagination-active-bg: #9d084a !important;
  --bs-pagination-bg: #180018 !important;
  --bs-pagination-border: none !important;
  --bs-pagination-active-border-color: #9d084a !important;
  --bs-pagination-color: white !important;
  --bs-pagination-hover-bg: #9d084a !important;
  --bs-pagination-hover-color: white !important;
  --bs-pagination-focus-color: white !important;
  --bs-pagination-focus-bg: #9d084a !important;
  --bs-pagination-focus-border: none !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(157, 8, 74, 0.25);
  font-family: "Poppins-SemiBold" !important;
}
