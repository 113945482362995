.label {
  font-family: "Degradasi";
  color: #180018;
}

.employeeDetails {
  font-family: "Poppins-Medium";
  color: #180018;
}

.userStat {
  font-family: "Poppins-Bold";
}

.searchBar {
  font-family: "Poppins-Regular";
  border-radius: 7px;
  border: none;
}

.imgProof {
  height: 70%;
  width: 70%;
}

.swtdHeader {
  background-color: #e8b4bc !important;
  font-family: "Poppins-SemiBold";
  border-radius: 10px 10px 0 0 !important;
}

.filterText {
  font-family: "Poppins-Medium";
}

.filterOption {
  font-family: "Poppins-Regular";
}

.tableHeader {
  background-color: #e8b4bc !important;
  font-family: "Poppins-Medium";
  color: #180018 !important;
  border-radius: 10px 10px 0 0 !important;
}

.tableBody {
  font-family: "Poppins-Regular";
  border-radius: 0 0 0 0 !important;
  cursor: pointer;
}

.tableBody:hover {
  background-color: rgb(232, 232, 232);
}

.cardHeader {
  background-color: #e8b4bc !important;
  font-family: "Poppins-SemiBold";
  font-size: 1.2rem;
}

.cardBody {
  font-family: "Poppins-Regular";
}

.swtdStat {
  font-family: "Poppins-SemiBold";
  font-size: 1.15em;
}

.swtdContent {
  font-family: "Poppins-Regular";
  font-size: 1.05em;
}

.formLabel {
  font-family: "Poppins-SemiBold";
}

.formBox {
  border: grey 1px solid !important;
}

.triangle {
  cursor: pointer;
}

.commentEdit {
  cursor: pointer;
  color: #3a3238;
}

.commentDelete {
  cursor: pointer;
  color: red;
}

.comment {
  font-family: "Poppins-Light";
}

.button {
  background-color: #9d084a !important;
  border: none !important;
}

.pointsBox {
  font-family: "Poppins-SemiBold";
  color: #9d084a !important;
}

.msg {
  font-family: "Poppins-SemiBold";
  color: #9d084a;
}

.termPoints {
  font-family: "Degradasi";
  line-height: 1.3em;
}

.validPoints {
  font-size: 2.3em;
}

.requiredPoints {
  font-size: 1.3em;
}

.blackCard {
  background-color: #180018 !important;
}

.cardTitle {
  color: white;
}

.listColor {
  background-color: black !important;
  color: white !important;
}

.depCol {
  color: white !important;
}

.depTitle {
  font-family: "Poppins-SemiBold";
  font-size: 1.15em;
}

.depStat {
  font-family: "Poppins-Regular";
  font-size: 0.9em;
}

.cardCol1 {
  font-family: "Poppins-Medium";
  color: white;
}

.lackingPercent {
  font-size: 2.3rem;
  font-family: "Poppins-Bold";
  text-align: center;
}

.pointsDisplay {
  padding-left: 1%;
  padding-right: 1%;
  background-color: white;
  border-radius: 5px;
  border: #9d084a solid;
  color: #9d084a;
}

/* .dept {
  background-color: #180018;
  color: white;
} */

.pageNum {
  --bs-pagination-active-bg: #9d084a !important;
  --bs-pagination-bg: #180018 !important;
  --bs-pagination-border: none !important;
  --bs-pagination-active-border-color: #9d084a !important;
  --bs-pagination-color: white !important;
  --bs-pagination-hover-bg: #9d084a !important;
  --bs-pagination-hover-color: white !important;
  --bs-pagination-focus-color: white !important;
  --bs-pagination-focus-bg: #9d084a !important;
  --bs-pagination-focus-border: none !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(157, 8, 74, 0.25);
  font-family: "Poppins-SemiBold" !important;
}

.bar {
  border-radius: 15px !important;
  border: 2px solid white !important;
  background-color: #e8b4bc !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  --bs-progress-bar-bg: #9d084a !important;
  color: var(--bs-progress-bar-bg) !important;
}
