.background {
  height: 100vh;
  background-color: #180018;
}

.header {
  background-color: #9d084a;
  padding: 10px;
  padding-left: 30px;
  padding-top: 20px;
  font-family: "Degradasi";
}

.triangle {
  color: white !important;
  cursor: pointer;
}

.icon {
  color: #180018 !important;
}

.brand {
  font-family: "Degradasi";
  font-size: 2rem;
  color: #9d084a;
  vertical-align: middle;
}

.formIcon {
  color: #180018;
}

.iconBox {
  background-color: white !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.form {
  font-family: "Poppins-Regular" !important;
}

.semibold {
  font-family: "Poppins-SemiBold" !important;
}

.image {
  margin-right: 10px;
}

.toastHeader {
  font-family: "Poppins-Medium" !important;
  color: white !important;
  background-color: #b22222 !important;
}

.toast {
  font-family: "Poppins-Regular" !important;
  color: #b22222 !important;
}

.passwordBox {
  border-right: none !important;
}

.iconEye {
  background-color: white !important;
}

.boldText {
  font-family: "Poppins-Bold";
  text-decoration: underline;
}

.boldText:hover {
  color: #9d084a;
  cursor: pointer;
}
