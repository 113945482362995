.pageTitle {
  font-family: "Degradasi";
  color: #180018;
}

.termDropdown {
  font-family: "Poppins-Medium";
  color: #180018;
}

.statCard {
  border: 1px solid #180018 !important;
}

.pendingStat {
  padding: 0% !important;
  font-family: "Poppins-SemiBold";
  border-bottom: 1px solid #180018 !important;
  color: white !important;
  background-color: #180018 !important;
}

.card {
  min-height: 100vh !important;
}

.userStatus {
  font-family: "Poppins-Medium";
}

.cardTitle {
  font-family: "Poppins-SemiBold";
  font-size: 1.15em;
}

.cardStat {
  font-family: "Poppins-Regular";
  font-size: 0.9em;
}

.searchBar {
  font-family: "Poppins-Regular";
  border-radius: 7px;
  border: none;
}

.deptDropdown {
  font-family: "Poppins-Regular";
}

.msg {
  font-family: "Poppins-SemiBold" !important;
  color: #9d084a !important;
}

.semibold {
  font-family: "Poppins-SemiBold";
}

.tableHeader {
  background-color: #e8b4bc !important;
  font-family: "Poppins-Medium";
  color: #180018 !important;
  border-radius: 10px 10px 0 0 !important;
}

.tableBody {
  font-family: "Poppins-Regular";
  border-radius: 0 0 0 0 !important;
  cursor: pointer;
}

.tableBody:hover {
  background-color: rgb(232, 232, 232);
}

.graphBackground {
  background-color: white;
  border-radius: 3px;
}

.activeTab {
  font-family: "Poppins-Bold";
  color: #f5e3e0 !important;
  background-color: #9d084a !important;
}

.inactiveTab {
  font-family: "Poppins-SemiBold";
  color: #f5e3e0 !important;
  background-color: #180018 !important;
}

.pageNum {
  --bs-pagination-active-bg: #9d084a !important;
  --bs-pagination-bg: #180018 !important;
  --bs-pagination-border: none !important;
  --bs-pagination-active-border-color: #9d084a !important;
  --bs-pagination-color: white !important;
  --bs-pagination-hover-bg: #9d084a !important;
  --bs-pagination-hover-color: white !important;
  --bs-pagination-focus-color: white !important;
  --bs-pagination-focus-bg: #9d084a !important;
  --bs-pagination-focus-border: none !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(157, 8, 74, 0.25);
  font-family: "Poppins-SemiBold" !important;
}
