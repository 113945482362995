.label {
  font-family: "Degradasi";
  color: #3a3238;
}

.navHeader {
  font-family: "Poppins-Medium";
  margin-right: 5px;
}

.activeTab {
  font-family: "Poppins-Bold";
  color: #f5e3e0 !important;
  background-color: #9d084a !important;
}

.inactiveTab {
  color: #f5e3e0 !important;
  background-color: #180018 !important;
}

.card {
  border-top-left-radius: 0 !important;
}

.form {
  font-family: "Poppins-Regular";
}

.formLabel {
  font-family: "Poppins-Medium";
}

.formBox {
  border: grey 1px solid !important;
}

.table {
  font-family: "Poppins-Regular" !important;
}

.searchBar {
  font-family: "Poppins-Regular";
  border-radius: 7px;
  border: none;
}

.icon {
  cursor: pointer;
}

.loading {
  font-family: "Poppins-SemiBold";
  color: #9d084a;
}

.formSection {
  font-family: "Poppins-SemiBold" !important;
}

.pageNum {
  --bs-pagination-active-bg: #9d084a !important;
  --bs-pagination-bg: #180018 !important;
  --bs-pagination-border: none !important;
  --bs-pagination-active-border-color: #9d084a !important;
  --bs-pagination-color: white !important;
  --bs-pagination-hover-bg: #9d084a !important;
  --bs-pagination-hover-color: white !important;
  --bs-pagination-focus-color: white !important;
  --bs-pagination-focus-bg: #9d084a !important;
  --bs-pagination-focus-border: none !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(157, 8, 74, 0.25);
  font-family: "Poppins-SemiBold" !important;
}
